import restaurant from "./images/23.png";
import biserica from "./images/catedrala.jpeg";
import img_card from "./images/alexandra.jpg";
import imgOmSuflet from "./images/ff.png";
import imgOmSufletmb from "./images/ffm.png";
import imgheader from "./images/f1.png";
import imgheadermb from "./images/f2m.png";
import img2 from "./images/sa.jpg";
import imgheadermiini from "./images/fo.png";
import imgheadermiinimb from "./images/fo.png";
import imgconfirmare from "./images/fd.png";
import logo from "./images/logo.png";


const data = {
    introData: [{
        familia: "S & A",
        logo: logo,
        mire: "",
        mireasa: "",
        data: "",
        data_confirmare: "15 septembrie 2024",
        savedata: "",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "sashashv@gmail.com", 
       tel: "+12673341900",
       phone: "tel:+12673341900",
       viber: "viber://chat?number=%2B12673341900",
       whatsapp: "https://wa.me/+12673341900",
       messenger: "https://www.messenger.com/t/xxx",
       tel1: "+12153996011",
       phone1: "tel:+12153996011",
       viber1: "viber://chat?number=%2B12153996011",
       whatsapp1: "https://wa.me/+12153996011",
       messenger1: "https://www.messenger.com/t/xxx",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Sfântul Botez al Alexandrei",
            localul: "Catedrala Mitropolitană",
            name: "Nașterea Domnului",
            data: "5 octombrie 2024, sâmbătă, ora 14:00",
            adress: "Piaţa Marii Adunări Naţionale 1, Chișinău",
            harta: "https://maps.app.goo.gl/tboFu7zTpWU4x5FT8",
            iframe: ""
        },
        {
            id: 2,
            img: restaurant,
            title: "Cumetria Alexandrei și a lui Sebastian",
            localul: "Restaurantul",
            name: "Villa Garden",
            data: "5 octombrie 2024, sâmbătă, ora 16:00",
            adress: "Bulevardul Decebal 16, Chișinău",
            harta: "https://maps.app.goo.gl/pwqyQnndwKAeacit7",
            iframe: "" 
        },
       

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "Sfântul Botez",
            title3: "al Alexandrei",
            mami: "Cristina",
            tati: "",
            message: "Vă invităm să împărtășim împreună încreștinarea scumpei noastre fetițe, la Catedrala Mitropolitană „Nașterea Domnului” din Chișinău, la ora 14:00.",
            message1: "",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "",
            title2: "Cumătria copiilor noștri",
            title3: "Sebastian și Alexandra",
            message: 'Vă invităm să sărbătorim și să petrecem împreună cu joc și voie bună celebrarea botezului copiilor noștri la restaurantul „Villa Garden” pe 5 octombrie 2024, ora 16:00.',
           
        } 
    ],
    blogDataOmSuflet: [
        {

            imgdesktop: imgOmSuflet,
            imgmobil: imgOmSufletmb,
            title: "",
            message: "",
            message1: "",
            parintii_nume1: "",
            parintii_nume2: "",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă ați fi alături de noi într-o zi atât de specială!",
            message: "",
        }
    ],

    blogDataConfirmare: [
        {
            imgdesktop: imgconfirmare,
            imgmobil: imgconfirmare,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
        }
    ],

}

export default data;