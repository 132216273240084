import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';

import Header from './components/Header';
import Home from './components/Home';
import FooterB from './components/Footer';
import './css/Body.css';
import data from './Data';


function App() {
  return (
    <>
 {data.introData.map((item, index) => {
                    return (
                      <Header familia={item.familia} mire={item.mire} mireasa={item.mireasa} logo={item.logo} /> 
                    )
                })}

     
     
     <Home/>
    
     <FooterB/>

    
    </>

   
  );
}

export default App;
