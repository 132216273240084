import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "../css/Style.css";


// import required modules
import { Autoplay, EffectCoverflow, Pagination } from "swiper";


import img1 from '../images/16.png';
import img2 from '../images/20.png';
import img3 from '../images/21.png';
import img4 from '../images/22.png';

const Slide = () => {
    let data = [
        {
            id: 1,
            imgSrc: img1,
           
        },
        {
            id: 2,
            imgSrc: img2,
         
        },
        {
            id: 3,
            imgSrc: img3,
         
        },
        {
            id: 4,
            imgSrc: img4,
         
        },
       
     

    ]
    return (
        <>
<h2 id="galerie" className="text-center mt-5" style={{ fontFamily: 'Dancing Script', fontSize: '40px' }}>Galerie</h2>
            <div className="slider mb-4 pb-4">
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={true}
                    modules={[Autoplay, EffectCoverflow, Pagination]}
                    className="mySwiper"
                >
                    {data.map((item, index) => {
                        return (<>
                       

                            <SwiperSlide>
                                

                                    <img src={item.imgSrc} />
                               
                                    
                              

                               

                            </SwiperSlide>


                    </>
                        )
                    })}

                </Swiper></div>
        </>
    );
}

export default Slide;